<template>
	<div class="host-create-form">
		<b-input placeholder="Host name" v-model="form.name"></b-input>
		<b-input placeholder="Email" v-model="form.email" type="email"
			message="Can be used for automatically sharing monthly reports"
		></b-input>
		<b-input placeholder="Phone number" v-model="form.mobile" type="tel"
			message="Can be used for slot cancellation notification"

		></b-input>
		<div class="form-field">
			<div class="form-field-label">Price per slot</div>
			<b-input v-model="form.price" type="number" expanded></b-input>
		</div>

		<div class="form-field payment-and-color">
			<b-checkbox v-model="form.isCreditAllowed" type="is-info">
				Allow advance payment
			</b-checkbox>
			<SlotColorPickerV2 v-model="cell"/>
		</div>

		<div class="form-field">
			<b-button type="is-info" @click="submit" expanded>Save</b-button>
			<b-button type="is-info is-light" @click="cancel" expanded>Cancel</b-button>
		</div>
	</div>
</template>

<script>
import Host from '../../../scripts/host';
import SlotColorPickerV2 from '../../inputs/SlotColorPickerV2.vue';
import HostCreateFormInst from './formInstance'

export default {
	name: 'HostCreateForm',
	components: {
		SlotColorPickerV2,
	},
	emits: [
		'on-submit',
		'on-cancel',
	],
	data() {
		return {
			form : new HostCreateFormInst(),
			textColor: "#000000",
		}
	},

	computed: {
		cell: {
			get() {
				return {
					mainColor: this.form.defaultColor ?? "lavender",
					textColor: this.textColor,
				}
			},
			set(value){
				this.form.defaultColor = value.mainColor
				this.textColor = value.textColor
			}
		}
	},

	methods: {
		submit(){
			let isValid = this.form.validate()
			if (!isValid) {
				return
			}

			this.$emit('on-submit', this.form)
		},
		cancel(){
			this.form.clear()
			this.$emit('on-cancel')
		},
	},
};
</script>

<style scoped>
.host-create-form{
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.form-field {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.form-field.payment-and-color{
	justify-content: space-between;
}

.form-field-label{
	width: 140px;
	height: 40px;
	display: flex;

	align-items: center;
}

</style>
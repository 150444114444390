<template>
	<div class="calendar-settings-host-menu">
		<div class="hosts-container" v-if="isLoaded">
			<div class="hosts" v-if="hosts && hosts.length > 0">
				<CollapseCard 
				v-for="host in hosts" 
				:key="`host_${host.id}`"
				@on-button-click="(ev) => openHostDetails(host.id)"
				:title="host.name">

					<template v-slot:header-before>
						<div class="host-tag-container">
							<div class="host-color" v-bind:style="{
								background: host.defaultBackgroundColor,
							}"></div>
						</div>
					</template>

					<template v-slot:header-after>
						<div class="host-tag-container">
							<b-tag class="host-slot-tag" v-if="exampleValue >= 0" type="is-success">
								{{ exampleValue }}
							</b-tag>
							<b-tag class="host-slot-tag" v-else type="is-danger">
								{{ exampleValue * -1}}
							</b-tag>
						</div>
					</template>

					<!-- <template v-slot:tools>
						<div class="card-footer-item">Edit</div>
						<div class="card-footer-item">Delete</div>
					</template>		 -->

				</CollapseCard>
			</div>

			<!-- Support message -->

			<div class="hosts empty" v-else>
				<div class="empty__title">
					No Hosts. 
				</div>
				<div class="empty__message">
					Click the Add button to open the host creation form.
				</div>
			</div>
		</div>

		<div v-if="!formIsOpen">
			<b-button v-if="!formIsOpen" 
				type="is-info is-outline" 
				@click="toggleForm" 
				expanded outlined>
				Add
			</b-button>
		</div>

		<HostCreateForm v-if="formIsOpen"
			@on-submit="createNewHost"
			@on-cancel="toggleForm"
		/>
	</div>
</template>

<script>
import { Button } from 'buefy'
import CollapseCard from '../inputs/CollapseCard.vue';
import HostCreateForm from '../forms/host-create/HostCreateForm.vue';
import CMS from '../../service/cms/service';
import Host from '../../scripts/host'

export default {
	name: 'HostMenu',
	components: {
		Button,
		CollapseCard,
		HostCreateForm,
	},
	computed: {
		calendarUid(){
			return this.$route.params.calendarUid
		},
		hosts(){
			let hosts = this.$store.getters.calendarSettingsHosts
			return hosts.map(host => {
				return {...host, isOpen: false}
			})
		},
	},

	created() {
		CMS.settings.getHosts(this.calendarUid)
			.then((data) => {
				if (data && data.err)
					throw data.err
				if (!data)
					return
				let hosts = Array.from(data.hosts)
					.map(hostData => new Host(hostData))

				this.$store.dispatch('update-settings-hosts', hosts)
			})
			.catch(_ => {})
			.finally(() => this.isLoaded = true)
	},


	data() {
		return {
			openedHostId: null,	
			formIsOpen: false,
			exampleValue: -3,
			isLoaded: false,
		};
	},

	mounted() {
		
	},

	methods: {
		openHost(hostId){
			if (this.openedHostId == hostId)
				this.openedHostId = null
			else 
				this.openedHostId = hostId
		},
		openHostDetails(hostId){
			this.$router.push({
				name: "calendar-settings-host",
				params: {
					hostId: hostId,
				}
			})
		},
		toggleForm(){
			this.formIsOpen = !this.formIsOpen
		},
		createNewHost(form){
			this.toggleForm()
			CMS.settings.createHost(this.calendarUid, form)
				.then((data) => {
					if (data && data.err)
						throw data.err
					if (!data)
						return
					let hosts = this.$store.getters.calendarSettingsHosts
					hosts.push(new Host({
						id: data.hostId,
						name: form.name,
						price: form.price,
						defaultBackgroundColor: form.defaultColor,
					}))
					this.$store.dispatch('update-settings-hosts', hosts)
				})
				.catch(_ => {})
		}
	},
};
</script>

<style scoped>
.calendar-settings-host-menu{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.hosts {
	animation: fade .3s;
}

.hosts.empty {
	gap: 10px;
	color: #808080;
	padding: 0 20px;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.empty__title{
	font-size: 20px;
	color: black;
}

.hosts-item__header{
	padding: 7px 6px;
    display: grid;
    grid-template-columns: calc(100% - 23px - 16px) 23px;
    grid-column-gap: 16px;
	background: #a8cdf6;
}

.hosts-item__details{
	border: 1px solid #a8cdf6;
	height: 36px;
}


.hosts-item__header > * {
	display: flex;
	align-items: center;
}

.hosts-item__details {
	display: flex;
	flex-direction: row;
	gap: 16px;
	padding: 10px;
}

.hosts-item-details__avatar{
	height: 30px;
	width: 30px;
	border: 1px solid black;
	border-radius: 15px;
}

.host-tag-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.host-color{
	height: 22px;
	width: 22px;
	border-radius: 4px;
	background: lavender;
}

.host-slot-tag{
	width: 45px;	
}
</style>
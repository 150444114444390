class HostCreateFormInst {
	name = null
	email = null
	mobile = null
	price = null
	isCreditAllowed = false
	defaultColor = null
	// credits = 0

	clear() {
		this.name = null
		this.email = null
		this.mobile = null
		this.price = null
		this.isCreditAllowed = false
		this.defaultColor = null
		// this.credits = 0
	}

	validate(){
		this.price = Number(this.price)

		return true
	}
}

export default HostCreateFormInst